import "./Sidebar.css";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

/* Import sidebar icons */
import FindIcon from "./imgs/find-icon.svg";
import FindIconActive from "./imgs/find-icon-active.svg";
import FeedIcon from "./imgs/feed-icon.svg";
import FeedIconActive from "./imgs/feed-icon-active.svg";
import ProfileIcon from "./imgs/profile-icon.svg";
import ProfileIconActive from "./imgs/profile-icon-active.svg";
import MessagesIcon from "./imgs/messages-icon.svg";
import MessagesIconActive from "./imgs/messages-icon-active.svg";
import LightningBolt from "./imgs/lightning-bolt.svg";
import ActiveBar from "./imgs/active-bar.svg";

function Sidebar() {
  const { user } = useAuth0();
  /* 0 is Find, 1 is Feed, 2 is Profile, 3 is Messages */
  const [active_tab, setActiveTab] = useState(0);
  const [bolt_active, setBoltActive] = useState(false);

  function handleTabChange(new_tab) {
    /* Change old tab css */
    const old_active_tab_text = document.querySelector(
      `#tabs li:nth-child(${active_tab + 1}) p`
    );
    old_active_tab_text.classList.remove("tab-active");

    /* Change new tab css */
    const new_active_tab_text = document.querySelector(
      `#tabs li:nth-child(${new_tab + 1}) p`
    );
    new_active_tab_text.classList.add("tab-active");

    /* Handle little red active bar */
    const active_bar = document.getElementById("active-bar");
    active_bar.classList.remove(`active-bar-${active_tab}`);
    active_bar.classList.add(`active-bar-${new_tab}`);

    setActiveTab(new_tab);
  }

  function handleBoltClick() {
    const sidebar = document.getElementById("sidebar");
    sidebar.classList.toggle("bolt-off");
    sidebar.classList.toggle("bolt-on");
    if (sidebar.classList.contains("bolt-on")) {
      for (let i = 0; i < 65; i++) {
        let circle = document.createElement("div");
        circle.classList.add("circle");
        let circle_container = document.createElement("div");
        circle_container.classList.add("circle-container");
        circle_container.appendChild(circle);
        sidebar.prepend(circle_container);
      }
    } else {
      const circles = document.querySelectorAll(".circle-container");
      for (let i = 0; i < circles.length; i++) {
        circles[i].remove();
      }
    }

    setBoltActive(!bolt_active);
  }

  return (
    <section id="sidebar" className="bolt-off">
      <div id="profile">
        <img src={user.picture} alt="User Profile Picture" />
        <p id="username">{user["https://qup.gg/username"]}</p>
        <div id="stats">
          <section id="stat-streak">
            <span className="stat-logo">🔥</span>
            <span>24</span>
          </section>
          <section id="stat-party">
            <span className="stat-logo">🎉</span>
            <span>132</span>
          </section>
        </div>
      </div>
      <img
        src={ActiveBar}
        alt="Active Indicator"
        id="active-bar"
        className="active-bar-0"
      />
      <ul id="tabs">
        <li
          onClick={() => {
            handleTabChange(0);
          }}
        >
          <img
            src={active_tab === 0 ? FindIconActive : FindIcon}
            alt="Find Friends Icon"
          />
          <p className="tab-active"> Find </p>
        </li>
        <li
          onClick={() => {
            handleTabChange(1);
          }}
        >
          <img
            src={active_tab === 1 ? FeedIconActive : FeedIcon}
            alt="Social Feed Icon"
          />
          <p> Feed </p>
        </li>
        <li
          onClick={() => {
            handleTabChange(2);
          }}
        >
          <img
            src={active_tab === 2 ? ProfileIconActive : ProfileIcon}
            alt="Profile Icon"
          />
          <p> Profile </p>
        </li>
        <li
          onClick={() => {
            handleTabChange(3);
          }}
        >
          <img
            src={active_tab === 3 ? MessagesIconActive : MessagesIcon}
            alt="Messages Icon"
          />
          <p> Messages </p>
        </li>
      </ul>
      <div id="the-button-container">
        <button
          className={`btn`}
          id="lightning-bolt-button"
          onClick={handleBoltClick}
        >
          <img src={LightningBolt} alt="Queue up Button" />
        </button>
        <p>
          {bolt_active
            ? "Your friends can now see a lightning bolt next to your name in their messages"
            : "Let your friends know you're ready to queue up"}
        </p>
      </div>
    </section>
  );
}

export default Sidebar;
