import AOS from "aos";
import "aos/dist/aos.css";
import "./styles/PlaceholderLanding.css";
import LandingMain from "./LandingMain.js";
import LandingFeatures from "./LandingFeatures.js";
import LandingAbout from "./LandingAbout.js";
import LandingAppPreviewSection from "./LandingAppPreviewSection.js";
import React, { useState } from "react";
import Modal from "../Modal";
import JoinForm from "../JoinForm";

function PlaceholderLanding() {
  const [isLightMode, setIsLightMode] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  function openModal() {
    setIsModalOpen(!isModalOpen);
  }

  AOS.init({
    delay: 200,
    duration: 1000,
    once: true,
  });

  return (
    <div
      className={`landing ${isModalOpen ? "on-modal-open" : ""} ${
        isLightMode ? "" : "dark-mode"
      }`}
    >
      <LandingMain
        openModal={openModal}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isLightMode={isLightMode}
        setIsLightMode={setIsLightMode}
      />
      <LandingAppPreviewSection />
      <LandingFeatures />
      <LandingAbout
        openModal={openModal}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isDarkMode={!isLightMode}
      />
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={"Join the Beta Release"}
      >
        <JoinForm />
      </Modal>
    </div>
  );
}

export default PlaceholderLanding;
