import "./styles/Button.css";

export default function Button(props) {
  return (
    <button
      type={props.type}
      className={`btn base-btn`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
