import axios from "axios";
import BaseButton from "./BaseButton";
import { useState } from "react";
import check from "../imgs/check.gif";
import "./JoinForm.css";

export default function JoinForm() {
  const [submitted, setSubmitted] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      userEmail: e.target.userEmail.value,
      favGames: e.target.favGames.value,
      ageGroup: e.target.ageGroup.value,
    };

    axios
      .post(
        "https://sheet.best/api/sheets/4df00b3f-db8f-4ede-b11c-58beaf2314c6",
        data
      )
      .then((response) => console.log(response));

    setTimeout(function () {
      setSubmitted(true);
    }, 1000);
  }

  const modalBody = (
    <div className="join-form-container">
      {submitted ? (
        <div className="join-form-submitted">
          <img src={check} alt="check gif" />
          You're on the waitlist! Keep an eye out for the beta release soon ;)
        </div>
      ) : (
        <>
          <div className="join-form-intro">
            We're still putting finishing touches on qup.gg but when we launch,
            you'll be the first to know!
          </div>
          <form className="join-form-input-container" onSubmit={handleSubmit}>
            <div className="join-form-section">
              <label className="join-form-section-text">Email: </label>
              <input
                className="form-control join-form-section-input"
                type="email"
                name="userEmail"
                required
              />
            </div>
            <div className="join-form-section">
              <label className="join-form-section-text">
                Your most-played multiplayer game(s):
              </label>
              <input
                className="form-control join-form-section-input"
                type="text"
                name="favGames"
                required
              />
            </div>
            <div className="join-form-section">
              <label className="join-form-section-text">Age Group</label>
              <select
                name="ageGroup"
                className="form-control join-form-section-input"
              >
                <option value="university"> University </option>
                <option value="highSchool"> High School </option>
                <option value="preHighSchool"> Pre-High School </option>
                <option value="postUniversity"> Post-University </option>
              </select>
            </div>
            <div className="join-waitlist-submit-button">
              <BaseButton type="submit">Sign me up</BaseButton>
            </div>
          </form>
        </>
      )}
    </div>
  );

  return modalBody;
}
