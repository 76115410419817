import React, { useState } from "react";
import "./styles/LandingMain.css";
import SunLogo from "../../imgs/SunLogo.svg";
import MoonLogo from "../../imgs/MoonLogo.svg";
import DarkSunLogo from "../../imgs/DarkSunLogo.svg";
import DarkMoonLogo from "../../imgs/DarkMoonLogo.svg";
import KiritunaProfile from "../../imgs/KiritunaProfile.svg";
import DarkKiritunaProfile from "../../imgs/DarkKiritunaProfile.svg";
import KennykalmanProfile from "../../imgs/KennykalmanProfile.svg";
import DarkKennykalmanProfile from "../../imgs/DarkKennykalmanProfile.svg";
import XirisiaxProfile from "../../imgs/XirisiaxProfile.svg";
import DarkXirisiaxProfile from "../../imgs/DarkXirisiaxProfile.svg";
import KiritunaPNG from "../../imgs/Kirituna.png";
import DarkKiritunaPNG from "../../imgs/DarkKirituna.png";
import KennykalmanPNG from "../../imgs/Kennykalman.png";
import DarkKennykalmanPNG from "../../imgs/DarkKennykalman.png";
import XirisiaxPNG from "../../imgs/Xirisiax.png";
import DarkXirisiaxPNG from "../../imgs/DarkXirisiax.png";
import LightHamburgerMenuIcon from "../../imgs/LightHamburgerMenuIcon.svg";
import DarkHamburgerMenuIcon from "../../imgs/DarkHamburgerMenuIcon.svg";
import DownArrow from "../../imgs/DownArrow.svg";
import ToggleSwitch from "../ToggleSwitch";
import OpenJoinModalButton from "../OpenJoinModalButton";
import IconLightningBoltButton from "../../imgs/IconLightningBoltButton.svg";

function shouldUsePNG() {
  // use PNG if not chrome since only chrome is good for SVGs - is this true?
  let isChrome = navigator.userAgent.indexOf("Chrome") > -1;
  return !isChrome;
}

function LandingMain(props) {
  /* Click anywhere toggles off the menu */
  window.onclick = function (event) {
    if (
      !event.target.matches("#topbar-right-button") &&
      !(event.target.matches(".slider.round") || event.target.matches("input"))
    ) {
      document.getElementById("topbar-right-dropdown").classList.add("hide");
      const button = document.getElementById("topbar-right-button");

      button.classList.remove("menu-button-on");
      button.classList.remove("light-mode-menu");
      button.classList.add("transparent-mode-menu");
    }
  };

  function toggleMenu() {
    document.getElementById("topbar-right-dropdown").classList.toggle("hide");
    const button = document.getElementById("topbar-right-button");

    button.classList.toggle("menu-button-on");
    updateButtonColorInverse(); /* Something funky happens with props updating*/
  }

  function updateMenuColor() {
    const menu = document.getElementById("topbar-right-dropdown");
    if (props.isLightMode) {
      menu.classList.add("light-mode-menu");
    } else {
      menu.classList.remove("light-mode-menu");
    }
  }

  function updateButtonColorInverse() {
    const button = document.getElementById("topbar-right-button");
    const is_menu_on = button.classList.contains("menu-button-on");
    if (is_menu_on && !props.isLightMode) {
      button.classList.add("light-mode-menu");
      button.classList.remove("transparent-mode-menu");
    } else if (is_menu_on) {
      button.classList.remove("light-mode-menu");
      button.classList.remove("transparent-mode-menu");
    } else {
      button.classList.remove("light-mode-menu");
      button.classList.add("transparent-mode-menu");
    }
  }

  function updateButtonColor() {
    const button = document.getElementById("topbar-right-button");
    const is_menu_on = button.classList.contains("menu-button-on");
    if (is_menu_on && props.isLightMode) {
      button.classList.add("light-mode-menu");
      button.classList.remove("transparent-mode-menu");
    } else if (is_menu_on) {
      button.classList.remove("light-mode-menu");
      button.classList.remove("transparent-mode-menu");
    } else {
      button.classList.remove("light-mode-menu");
      button.classList.add("transparent-mode-menu");
    }
  }

  /* Render profile cards based on browser - choose PNG or SVG */

  function getKirituna() {
    if (shouldUsePNG()) {
      return <img src={KiritunaPNG} className="profile-card-png" />;
    } else {
      return <img src={KiritunaProfile} width={288} />;
    }
  }

  function getDarkKirituna() {
    if (shouldUsePNG()) {
      return <img src={DarkKiritunaPNG} className="profile-card-png" />;
    } else {
      return <img src={DarkKiritunaProfile} width={288} />;
    }
  }

  function getKennykalman() {
    if (shouldUsePNG()) {
      return <img src={KennykalmanPNG} className="profile-card-png" />;
    } else {
      return <img src={KennykalmanProfile} width={288} />;
    }
  }

  function getDarkKennykalman() {
    if (shouldUsePNG()) {
      return <img src={DarkKennykalmanPNG} className="profile-card-png" />;
    } else {
      return <img src={DarkKennykalmanProfile} width={288} />;
    }
  }

  function getXirisiax() {
    if (shouldUsePNG()) {
      return <img src={XirisiaxPNG} className="profile-card-png" />;
    } else {
      return <img src={XirisiaxProfile} width={288} />;
    }
  }

  function getDarkXirisiax() {
    if (shouldUsePNG()) {
      return <img src={DarkXirisiaxPNG} className="profile-card-png" />;
    } else {
      return <img src={DarkXirisiaxProfile} width={288} />;
    }
  }

  return (
    <div className="background1">
      <div className="topbar">
        <div className="topbar-left">
          <div className="qupgg-logo">
            <img
              src={IconLightningBoltButton}
              className="icon-lightning-bolt-button"
            />
            qup.gg
          </div>
        </div>
        <div className="topbar-right hide" id="topbar-right-dropdown">
          <div
            className="dropdown-light-dark-toggle"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="moon-logo">
              {props.isLightMode ? (
                <img src={MoonLogo} />
              ) : (
                <img src={DarkMoonLogo} />
              )}
            </div>
            <div className="slider-logo">
              <ToggleSwitch
                onChange={() => {
                  props.setIsLightMode(!props.isLightMode);
                  updateMenuColor();
                  updateButtonColor();
                }}
              />
            </div>

            <div className="sun-logo">
              {props.isLightMode ? (
                <img src={SunLogo} />
              ) : (
                <img src={DarkSunLogo} />
              )}
            </div>
          </div>
          <a
            href="#app-preview"
            className={`topbar-features ${
              !props.isLightMode ? "topbar-dark-mode" : ""
            }`}
          >
            Preview
          </a>
          <a
            href="#features"
            className={`topbar-about ${
              !props.isLightMode ? "topbar-dark-mode" : ""
            }`}
          >
            Features
          </a>
        </div>
        <input
          type="image"
          id="topbar-right-button"
          onClick={toggleMenu}
          src={
            !props.isLightMode ? DarkHamburgerMenuIcon : LightHamburgerMenuIcon
          }
        />
      </div>
      <div className="landing-page1-container">
        <div className="landing-page1-left">
          <div className="queueup fade-in-queueup">Queue up?</div>
          <div className="social-gaming-text-container fade-in-social-gaming-text">
            <div className="subtext-phrases">
              <div className="subtext-phrase">
                A <span id="first-bold">new</span> type of social network for
                both casual and competitive gamers
              </div>
              <div className="subtext-phrase">
                One button that fixes everything - click it and instantly see
                which of your friends want to play right now.
              </div>
              <div className="subtext-phrase">
                Customize your profile and find new friends to play with. No
                profile picture uploads though- we aren't Tinder :)
              </div>
              <div className="subtext-phrase">
                Check out your friends' most insane highlights and hilarious
                fails on your personalized feed.
              </div>
            </div>
          </div>
          <div className="join-button fade-in-join-button">
            <OpenJoinModalButton
              isDarkMode={!props.isLightMode}
              openModal={props.openModal}
              isModalOpen={props.isModalOpen}
              setIsModalOpen={props.setIsModalOpen}
            />
          </div>
        </div>
        <div className="landing-page1-right">
          <div className="profile-cards">
            {/* Aspect ratio must be kept */}
            {props.isLightMode ? (
              <div className="fade-in-cards xirisiax-card">{getXirisiax()}</div>
            ) : (
              <div className="fade-in-cards xirisiax-card">
                {getDarkXirisiax()}
              </div>
            )}
            {props.isLightMode ? (
              <div className="fade-in-cards kennykalman-card">
                {getKennykalman()}
              </div>
            ) : (
              <div className="fade-in-cards kennykalman-card">
                {getDarkKennykalman()}
              </div>
            )}
            {props.isLightMode ? (
              <div className="fade-in-cards kirituna-card">{getKirituna()}</div>
            ) : (
              <div className="fade-in-cards kirituna-card">
                {getDarkKirituna()}
              </div>
            )}
          </div>
          {/* <div className="down-arrow-container fade-in-down-arrow">
            <div className="down-arrow">
              <a href="#app-preview">
                <img src={DownArrow} />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LandingMain;
