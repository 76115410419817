import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./components/root/Home";
import PlaceholderLanding from "./components/PlaceholderLanding/PlaceholderLanding";

function App() {
  return <PlaceholderLanding />;
  // return <Home />;
}

export default App;
