import React from "react";
import "./ToggleSwitch.css";

function ToggleSwitch(props) {
  return (
    <div>
      <label className="switch">
        <input type="checkbox" onChange={props.onChange} />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
