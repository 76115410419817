import TopBar from "./components/TopBar";
import HomeMainContainer from "./components/HomeMainContainer";
import "./styles/Home.css";

function Home() {
  return (
    <main id="home-container">
      <TopBar />
      <HomeMainContainer />
    </main>
  );
}

export default Home;
