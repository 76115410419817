import "./styles/LandingAppPreviewSection.css";
import AppPreviewChromeTab from "../../imgs/LandingAppPreview/AppPreviewChromeTab.svg";
import AppPreviewTopBar from "../../imgs/LandingAppPreview/AppPreviewTopBar.svg";
import AppPreviewMessaging from "../../imgs/LandingAppPreview/AppPreviewMessaging.svg";
import AppPreviewSidebar from "../../imgs/LandingAppPreview/AppPreviewSidebar.svg";
import AppPreviewSwiping from "../../imgs/LandingAppPreview/AppPreviewSwiping.svg";
import AppPreviewFull from "../../imgs/LandingAppPreview/AppPreviewFull.png";

function shouldUsePNG() {
  // use PNG if not chrome since only chrome is good for SVGs - is this true?
  let isChrome = navigator.userAgent.indexOf("Chrome") > -1;
  return !isChrome;
}

function LandingAppPreviewSection() {
  return (
    <div className="landing-app-preview-section-container" id="app-preview">
      <div className="landing-app-preview-section-title">
        Welcome to your new home for all things gaming
      </div>
      {shouldUsePNG() ? (
        <img id="app-preview-full-img" src={AppPreviewFull} />
      ) : (
        <div
          className="landing-app-preview-section-image-container"
          data-aos="fade-in"
        >
          <img src={AppPreviewChromeTab} id="app-preview-chrome-tab" />
          <div className="landing-app-preview-section-topbar-and-main-container">
            <img
              src={AppPreviewTopBar}
              data-aos="fade-in"
              data-aos-delay="500"
              id="app-preview-topbar"
            />

            <div className="landing-app-preview-section-main-container">
              <img
                src={AppPreviewSidebar}
                id="preview-sidebar"
                data-aos="fade-in"
                data-aos-delay="1000"
              />
              <img
                src={AppPreviewSwiping}
                id="preview-swiping"
                data-aos="fade-in"
                data-aos-delay="1500"
              />
              <img
                src={AppPreviewMessaging}
                id="preview-messaging"
                data-aos="fade-in"
                data-aos-delay="2000"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LandingAppPreviewSection;
