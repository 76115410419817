import "./styles/LandingFeatures.css";
import LandingFeaturesFeed from "../../imgs/LandingFeatures/LandingFeaturesFeed.svg";
import LandingFeaturesScrollMessages from "../../imgs/LandingFeatures/LandingFeaturesScrollMessages.svg";
import LandingFeaturesNoProfilePics from "../../imgs/LandingFeatures/LandingFeaturesNoProfilePics.svg";
import LandingFeaturesFeedPNG from "../../imgs/LandingFeatures/LandingFeaturesFeed.png";
import LandingFeaturesScrollMessagesPNG from "../../imgs/LandingFeatures/LandingFeaturesScrollMessages.png";
import LandingFeaturesNoProfilePicsPNG from "../../imgs/LandingFeatures/LandingFeaturesNoProfilePics.png";
import LandingFeaturesSwipingPNG from "../../imgs/LandingFeatures/LandingFeaturesSwiping.png";
import LightningBolt from "../../imgs/LightningBolt.svg";
import LightningBoltButton from "../../imgs/LightningBoltButton.svg";
import AppPreviewSwiping from "../../imgs/LandingAppPreview/AppPreviewSwiping.svg";

function shouldUsePNG() {
  // use PNG if not chrome since only chrome is good for SVGs - is this true?
  let isChrome = navigator.userAgent.indexOf("Chrome") > -1;
  return !isChrome;
}

function LandingFeatures() {
  return (
    <div className="landing-features-container" id="features">
      <div className="landing-features-title">Features</div>
      <div
        className="landing-features-component lightning-bolt-button"
        data-aos="fade-in"
      >
        <div className="landing-features-lightning-bolt-button-image">
          <img src={LightningBoltButton} />
        </div>
        <div className="landing-features-lightning-bolt-button-text">
          Click one button: let your friends know you're in the mood to play
        </div>
      </div>
      <div
        className="landing-features-component scroll-messages"
        data-aos="fade-in"
      >
        <div className="landing-features-scroll-messages-text">
          Look for the
          <span className="in-paragraph-lightning-bolt">
            <img src={LightningBolt} />
          </span>
          next to your friend's names to quickly see who is actively searching
          for someone to play with
        </div>
        <div className="landing-features-scroll-messages-image">
          {shouldUsePNG() ? (
            <img
              src={LandingFeaturesScrollMessagesPNG}
              className="scroll-messages-png"
            />
          ) : (
            <img src={LandingFeaturesScrollMessages} />
          )}
        </div>
      </div>
      <div className="landing-features-component swiping" data-aos="fade-in">
        <div className="landing-features-swiping-image">
          {shouldUsePNG() ? (
            <img
              src={LandingFeaturesSwipingPNG}
              className="features-swiping-png"
            />
          ) : (
            <img src={AppPreviewSwiping} className="features-swiping" />
          )}
        </div>
        <div className="landing-features-swiping-text">
          No awkwardly messaging all-chat in some Discord server. Easily find
          other gamers who want to play right now
        </div>
      </div>
      <div className="landing-features-component feed" data-aos="fade-in">
        <div className="landing-features-feed-text">
          Be part of the community. Easily post your own hype moments, watch
          gaming highlights and fails
        </div>
        <div className="landing-features-feed-image">
          {shouldUsePNG() ? (
            <img src={LandingFeaturesFeedPNG} className="features-feed-png" />
          ) : (
            <img src={LandingFeaturesFeed} />
          )}
        </div>
      </div>
      <div
        className="landing-features-component no-profile-pictures"
        data-aos="fade-in"
      >
        <div className="landing-features-no-profile-pictures-image">
          {shouldUsePNG() ? (
            <img
              src={LandingFeaturesNoProfilePicsPNG}
              className="features-no-profile-pics-png"
            />
          ) : (
            <img src={LandingFeaturesNoProfilePics} />
          )}
        </div>
        <div className="landing-features-no-profile-pictures-text">
          Stay anonymous - no profile picture uploads allowed!
        </div>
      </div>
    </div>
  );
}

export default LandingFeatures;
