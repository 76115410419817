import "./styles/HomeMainContainer.css";
import Sidebar from "./Sidebar/Sidebar";
import Central from "./Central/Central";
import Messages from "./Messages/Messages";

function HomeMainContainer() {
  return (
    <section id="main-container">
      <Sidebar />
      <Central />
      <Messages />
    </section>
  );
}

export default HomeMainContainer;
