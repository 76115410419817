import "./Messages.css";
import { StreamChat } from "stream-chat";
import { useAuth0 } from "@auth0/auth0-react";

function Messages() {
  // const { user } = useAuth0();
  const client = StreamChat.getInstance("9ygdhs3gjvb5");
  // console.log(client);
  // await clientInformation.connectUser(
  //   {
  //     id: user["https://qup.gg/username"],
  //     name: user["https://qup.gg/username"],
  //     image: user.picture,
  //   },
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoic2lsZW50LWJyb29rLTgifQ.AfJPqnwip9RxbR7YOCNN0tL9DDh5Ug3gjVO-yE3T9C4",
  // )

  // const channel = client.channel("messaging", "travel", {name: "Hawaii boys"});
  // await channel.watch();

  // const text = "Oy LEVI WHO'S READY FOR HAWAII";

  // const response = await channel.sendMessage({text, customField: "123"});

  // channel.on("message.new", event => {
  //   console.log("received a new message", event.message.text);
  //   console.log(`Now have ${channel.state.messages.length} stored locally`);
  // })

  return <div id="messages">Hi</div>;
}

export default Messages;
