import "./BaseButton.css";

export default function BaseButton(props) {
  return (
    <button
      type={props.type}
      className={`btn base-button ${props.isDarkMode ? "dark-mode" : ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
