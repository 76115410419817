import React from "react";
import "./Modal.css";
import close_icon from "../imgs/close_icon.png";

export default function Modal(props) {
  function toggleModal() {
    props.setIsOpen(!props.isOpen);
  }

  function modalClick(e) {
    e.stopPropagation();
  }

  return (
    <div
      className={"modal-overlay " + (props.isOpen ? "open" : "closed")}
      onClick={toggleModal}
    >
      <div className="modal-container" onClick={modalClick}>
        <div className="modal-title">{props.title}</div>
        <img
          className="modal-close"
          src={close_icon}
          onClick={toggleModal}
          alt="Close"
        />
        {props.children}
      </div>
    </div>
  );
}
