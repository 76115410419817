import BaseButton from "./BaseButton";
import "./OpenJoinModalButton.css";

function OpenJoinModalButton(props) {
  return (
    <div>
      <BaseButton onClick={props.openModal} isDarkMode={props.isDarkMode}>
        Join Beta
      </BaseButton>
    </div>
  );
}

export default OpenJoinModalButton;
