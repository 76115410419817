import "./styles/TopBar.css";
import Button from "./Button";
import Icon from "../../../imgs/IconLightningBoltButton.svg";
import { useAuth0 } from "@auth0/auth0-react";

function TopBar() {
  const { logout } = useAuth0();
  return (
    <nav>
      <img src={Icon} alt="Qup.gg Icon" />
      <h1>qup.gg</h1>
      <Button onClick={() => logout({ returnTo: "http://localhost:3000" })}>
        <p>Log Out</p>
      </Button>
    </nav>
  );
}

export default TopBar;
