import "./styles/LandingAbout.css";
import OpenJoinModalButton from "../OpenJoinModalButton";

function LandingAbout(props) {
  return (
    <div className="background4">
      <div className="bottom-join-container" data-aos="fade-in">
        <div className="ready-to-run-it-text">Ready to run it?</div>
        <div className="bottom-join-button">
          <OpenJoinModalButton
            isDarkMode={props.isDarkMode}
            openModal={props.openModal}
            isModalOpen={props.isModalOpen}
            setIsModalOpen={props.setIsModalOpen}
          />
        </div>
      </div>
    </div>
  );
}

export default LandingAbout;
